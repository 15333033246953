/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.modal-detail-product {
    --border-radius: 25px 25px 0 0;
}

.modal-semi-transparent {
    --background: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.5);
}

.modal-fullscreen {
    --width:100% !important;
    --height:100% !important;
}

.select-class {
    .action-sheet-button {
        color: black;
    }
}

.cancel-sheet-button {
    color: black;
    font-weight: bold;
}

.toast-class {
    --background: white;
    --color: black;
    font-size: 1.1rem;
    --min-height: 60px;
    --start: 16px;
    --end: 16px;

    &::part(icon){
        font-size: 2.5rem;
    }
}

.toast-error::part(icon) {
    color: #f27474;   
}

.toast-success::part(icon) {
    color: #a5dc86;   
}

.alert-class {
    .alert-wrapper {
        padding-bottom: 10px;
    }

    .alert-message {
        max-height: fit-content;
    }

   .alert-button-group .alert-button {
        border-radius: 4px;
        background-color: #6e7881;
        color: white;
    }

    .alert-button-group .alert-button-role-confirm {
        background-color: var(--ion-color-buttons);
        color: var(--ion-color-buttons-contrast);
    }
}

.alert-html {
    .alert-title {
        font-size: 16px;
        text-align: center;
    }

    .alert-button-group {
        justify-content: center;
        padding-inline-end: 0;
        --webkit-padding-end: 0;
    }
}

ion-refresher.refresher-native ion-spinner, ion-refresher.refresher-native ion-icon {
    color: var(--ion-color-icons) !important;
}

