// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

//Correção de bug placeholder no input ao trocar de tamanho e fonte
.input-fill-outline.input-label-placement-stacked .label-text-wrapper,
.input-fill-outline.input-label-placement-floating .label-text-wrapper {
    top: 50%;
    transform: translateY(-50%) scale(1) !important;
	transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1), transform 150ms cubic-bezier(0.4, 0, 0.2, 1), top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.label-floating.input-fill-outline .label-text-wrapper {
	top: 0;
	transform: translateY(-32%) scale(0.75) !important;
}


swiper-container {
	--swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
	--swiper-pagination-color: var(--ion-color-buttons);
	--swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
	--swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
	--swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

ion-input {
    // --border-color: var(--ion-color-inputs) !important;
	--highlight-color: var(--ion-color-inputs) !important;
}

ion-select {
	// --border-color: var(--ion-color-inputs) !important;
	--highlight-color: var(--ion-color-inputs) !important;
}

ion-progress-bar {
	--background: var(--ion-color-headers);
	--progress-background: var(--ion-color-headers-contrast);
}

.ion-color-buttons {
	--ion-color-base: var(--ion-color-buttons);
	--ion-color-base-rgb: var(--ion-color-buttons-rgb);
	--ion-color-contrast: var(--ion-color-buttons-contrast);
	--ion-color-contrast-rgb: var(--ion-color-buttons-contrast-rgb);
	--ion-color-shade: var(--ion-color-buttons-shade);
	--ion-color-tint: var(--ion-color-buttons-tint);
}

.ion-color-inputs {
	--ion-color-base: var(--ion-color-inputs);
	--ion-color-base-rgb: var(--ion-color-inputs-rgb);
	--ion-color-contrast: var(--ion-color-inputs-contrast);
	--ion-color-contrast-rgb: var(--ion-color-inputs-contrast-rgb);
	--ion-color-shade: var(--ion-color-inputs-shade);
	--ion-color-tint: var(--ion-color-inputs-tint);
}

.ion-color-headers {
	--ion-color-base: var(--ion-color-headers);
	--ion-color-base-rgb: var(--ion-color-headers-rgb);
	--ion-color-contrast: var(--ion-color-headers-contrast);
	--ion-color-contrast-rgb: var(--ion-color-headers-contrast-rgb);
	--ion-color-shade: var(--ion-color-headers-shade);
	--ion-color-tint: var(--ion-color-headers-tint);
}

.ion-color-icons {
	--ion-color-base: var(--ion-color-icons);
	--ion-color-base-rgb: var(--ion-color-icons-rgb);
	--ion-color-contrast: var(--ion-color-icons-contrast);
	--ion-color-contrast-rgb: var(--ion-color-icons-contrast-rgb);
	--ion-color-shade: var(--ion-color-icons-shade);
	--ion-color-tint: var(--ion-color-icons-tint);
}

.ion-color-cards {
	--ion-color-base: var(--ion-color-cards);
	--ion-color-base-rgb: var(--ion-color-cards-rgb);
	--ion-color-contrast: var(--ion-color-cards-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cards-contrast-rgb);
	--ion-color-shade: var(--ion-color-cards-shade);
	--ion-color-tint: var(--ion-color-cards-tint);
}

.ion-color-club {
	--ion-color-base: var(--ion-color-club);
	--ion-color-base-rgb: var(--ion-color-club-rgb);
	--ion-color-contrast: var(--ion-color-club-contrast);
	--ion-color-contrast-rgb: var(--ion-color-club-contrast-rgb);
	--ion-color-shade: var(--ion-color-club-shade);
	--ion-color-tint: var(--ion-color-club-tint);
}

.ion-color-offer {
	--ion-color-base: var(--ion-color-offer);
	--ion-color-base-rgb: var(--ion-color-offer-rgb);
	--ion-color-contrast: var(--ion-color-offer-contrast);
	--ion-color-contrast-rgb: var(--ion-color-offer-contrast-rgb);
	--ion-color-shade: var(--ion-color-offer-shade);
	--ion-color-tint: var(--ion-color-offer-tint);
}

.ion-color-discount {
	--ion-color-base: var(--ion-color-discount);
	--ion-color-base-rgb: var(--ion-color-discount-rgb);
	--ion-color-contrast: var(--ion-color-discount-contrast);
	--ion-color-contrast-rgb: var(--ion-color-discount-contrast-rgb);
	--ion-color-shade: var(--ion-color-discount-shade);
	--ion-color-tint: var(--ion-color-discount-tint);
}

.ion-color-cashback {
	--ion-color-base: var(--ion-color-cashback);
	--ion-color-base-rgb: var(--ion-color-cashback-rgb);
	--ion-color-contrast: var(--ion-color-cashback-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cashback-contrast-rgb);
	--ion-color-shade: var(--ion-color-cashback-shade);
	--ion-color-tint: var(--ion-color-cashback-tint);
}

.ion-color-badge {
	--ion-color-base: var(--ion-color-badge);
	--ion-color-base-rgb: var(--ion-color-badge-rgb);
	--ion-color-contrast: var(--ion-color-badge-contrast);
	--ion-color-contrast-rgb: var(--ion-color-badge-contrast-rgb);
	--ion-color-shade: var(--ion-color-badge-shade);
	--ion-color-tint: var(--ion-color-badge-tint);
}

.title-text-color {
	color: var(--title-text-color);
}

:root {
	--ion-font-family: 'Nunito', 'sans-serif';
	--ion-dynamic-font: var(--ion-font-family);
	--ion-default-font: var(--ion-font-family) !important;

	--ion-color-club: #237109;
	--ion-color-club-rgb: 35,113,9;
	--ion-color-club-contrast: #ffffff;
	--ion-color-club-contrast-rgb: 255,255,255;
	--ion-color-club-shade: #1f6308;
	--ion-color-club-tint: #397f22;

	--ion-color-offer: #C50707;
	--ion-color-offer-rgb: 197,7,7;
	--ion-color-offer-contrast: #ffffff;
	--ion-color-offer-contrast-rgb: 255,255,255;
	--ion-color-offer-shade: #ad0606;
	--ion-color-offer-tint: #cb2020;

	--ion-color-discount: #59d48a;
	--ion-color-discount-rgb: 89,212,138;
	--ion-color-discount-contrast: #000000;
	--ion-color-discount-contrast-rgb: 0,0,0;
	--ion-color-discount-shade: #4ebb79;
	--ion-color-discount-tint: #6ad896;

	--ion-color-cashback: #3880ff;
	--ion-color-cashback-rgb: 56,128,255;
	--ion-color-cashback-contrast: #000000;
	--ion-color-cashback-contrast-rgb: 0,0,0;
	--ion-color-cashback-shade: #3171e0;
	--ion-color-cashback-tint: #4c8dff;

	--ion-color-badge: #FFD700;
	--ion-color-badge-rgb: 255,215,0;
	--ion-color-badge-contrast: #000000;
	--ion-color-badge-contrast-rgb: 0,0,0;
	--ion-color-badge-shade: #e0bd00;
	--ion-color-badge-tint: #ffdb1a;

	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}
